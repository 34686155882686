.brief {
    display: flex;
    justify-content: center;
    background-color: #FCFCFC;
    width: 100vw;
    flex-direction: column;
    align-items: center;
    padding: 100px;
}

.cast {
    width:1280px;
    margin-top:50px;
    display:flex;
    align-items:start;
    flex-direction:row;
}

.container {
    width: 80%;
    margin: 0 60px 0px 60px;
    display: flex;
    flex-direction: column;
    gap: 100px;
}

.bts_detail {
    width:45%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-Items: start;
    margin: 0 20px 0 20px;
    right: 0px;
    gap: 15px;
}