.animated-element {
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 1s, transform 1s;
  }
  
  .animated-element.visible {
    opacity: 1;
    transform: translateY(0);
  }
  